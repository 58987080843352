<template>
  <div class="singpage-container" :class="{ 'layout-border': !isReviewPage }">
    <SingPage
      :singList="singList"
      :songInfo="songInfo"
      :timeStampList="timeStampList"
      :isReviewPage="isReviewPage"
    ></SingPage>
  </div>
</template>
<script>
import SingPage from "@/components/Course/CoursePage/SingPage";

export default {
  props: {
    isReviewPage: {
      type: Boolean,
    },
  },
  components: {
    SingPage,
  },
  data() {
    return {
      songInfo: {
        songUrl: require("@/assets/audio/songs/lesson-57.mp3"),
      },
      timeStampList: [6.1, 9.6, 12.5, 15.5, 19, 22.5],
      singList: {
        lyricsArr: [
          {
            id: 1,
            spelling: "Jīntiān shì jǐ yuè jǐ hào?",
            chineseWords: "今天是几月几号？",
          },
          {
            id: 2,
            spelling: "Jīntiān shì shí yuè yī hào.",
            chineseWords: "今天是10月一号。",
          },
          {
            id: 3,
            spelling: "Jīntiān shì shéi de shēngrì?",
            chineseWords: "今天是谁的生日？",
          },
          {
            id: 4,
            spelling: "Jīntiān shì wǒ de shēngrì.",
            chineseWords: "今天是我的生日。",
          },
          {
            id: 5,
            spelling: "Wǒ xǐhuan chī shēngrì dàngāo!",
            chineseWords: "我喜欢吃生日蛋糕！",
          },
        ],
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.singpage-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
}
</style>
